import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../../node_modules/react-modal-video/css/modal-video.css";
import Footer from "../../components/footer";
import Spinner from "../../common/loading-spinner";
import Navbar from "../../components/navbar";
import RoutesEnums from "../../enums/routes.enums";
import GetInTuch from "../../components/getInTuch";
import FooterTopImage from "../../components/FoterTopImage";
import dots from "../../assect/images/svg/dots.svg";
import hero1 from "../../assect/images/website/pic1.jpg";
import hero2 from "../../assect/images/website/pic2.jpg";
import hero3 from "../../assect/images/website/pic3.jpg";
import newsLetter from "../../assect/images/newsLetter.jpg";
import whoWeAreBig from "../../assect/images/website/40.jpg";
import whoWeAreSmall from "../../assect/images/website/39.jpg";
import whatWeDoBig from "../../assect/images/website/pic6.jpg";
import whatWeDoSmall from "../../assect/images/website/7.jpg";

export default function IndexThree() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [state, setState] = useState("Active");
  // const [count, setCount] = useState(0);
  // const [remaining, setRemaining] = useState(0);

  // const onIdle = () => {
  //   setState("Idle");
  //   navigate(RoutesEnums.LOGIN);
  // };

  // const onActive = () => {
  //   setState("Active");
  // };

  // const onAction = () => {
  //   setCount(count + 1);
  // };

  // const { getRemainingTime } = useIdleTimer({
  //   onIdle,
  //   onActive,
  //   onAction,
  //   timeout: 1000 * 60 * 10, //10 minutes
  //   throttle: 500,
  // });

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setRemaining(Math.ceil(getRemainingTime() / 1000));
  //   }, 500);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // });

  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="swiper-slider-hero position-relative d-block vh-100"
        id="home"
      >
        {loading && <Spinner />}
        <Carousel
          infiniteLoop={true}
          className="vh-100"
          autoPlay={true}
          showThumbs={false}
          showStatus={false}
        >
          <div className="slide-inner d-flex align-items-center vh-100 position-relative">
            <div
              className="background-wrapper"
              style={{ backgroundImage: `url(${hero1})` }}
            ></div>
            <div className="bg-overlay"></div>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="title-heading text-start">
                    <h1 className="heading fw-bold text-white title-dark mb-3">
                      Peace of mind now and for the future
                    </h1>
                    {/* <p className="para-desc text-white-50 title-dark mb-0">
                      A great platform to buy, sell and rent your properties
                      without any agent or commissions.
                    </p> */}

                    <div className="mt-4 pt-2">
                      <Link
                        to={RoutesEnums.SERVICES}
                        className="btn btn-pills btn-primary"
                      >
                        Services <i className="mdi mdi-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-outer">
            <div className="slide-inner d-flex align-items-center vh-100">
              <div
                className="background-wrapper"
                style={{ backgroundImage: `url(${hero2})` }}
              >
                <div className="bg-overlay"></div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="title-heading text-start">
                      <h1 className="heading fw-bold text-white title-dark mb-3">
                        Protecting what matters to you
                      </h1>
                      {/* <p className="para-desc text-white-50 title-dark mb-0">
                        A great platform to buy, sell and rent your properties
                        without any agent or commissions.
                      </p> */}

                      <div className="mt-4 pt-2">
                        <Link
                          to={RoutesEnums.SERVICES}
                          className="btn btn-pills btn-primary"
                        >
                          Services <i className="mdi mdi-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-outer">
            <div className="slide-inner d-flex align-items-center vh-100">
              <div
                className="background-wrapper"
                style={{ backgroundImage: `url(${hero3})` }}
              >
                <div className="bg-overlay"></div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="title-heading text-start">
                      <h1 className="heading fw-bold text-white title-dark mb-3">
                        Insurance specialists
                      </h1>
                      {/* <p className="para-desc text-white-50 title-dark mb-0">
                        A great platform to buy, sell and rent your properties
                        without any agent or commissions.
                      </p> */}

                      <div className="mt-4 pt-2">
                        <Link
                          to={RoutesEnums.SERVICES}
                          className="btn btn-pills btn-primary"
                        >
                          Services <i className="mdi mdi-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </section>
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="about-left">
                <div className="position-relative shadow p-2 rounded-top-pill rounded-5 bg-white img-one">
                  <img
                    src={whoWeAreBig}
                    className="img-fluid rounded-top-pill rounded-5"
                    alt=""
                  />
                  <div className="position-absolute top-0 start-0 z-n1">
                    <img src={dots} className="avatar avatar-xl-large" alt="" />
                  </div>
                </div>

                <div className="img-two shadow rounded-3 overflow-hidden p-2 bg-white">
                  <img
                    src={whoWeAreSmall}
                    className="img-fluid rounded-3"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h4 className="title mb-3">Who we are</h4>
                <p className="text-muted para-desc mb-0">
                  At Piertop we are more than an insurance agency- we’re your
                  dedicated partners in protection, committed to safeguarding
                  what matters most to you. With a steadfast focus on integrity,
                  reliability, and personalized service, we stand as a beacon of
                  security in an uncertain world. Whether you're protecting
                  yourself, your loved ones, home, vehicle, or business, we are
                  your trusted partners in acquiring the comprehensive coverage
                  and personalized attention you deserve.
                </p>

                <div className="mt-4">
                  <Link to="/aboutus" className="btn btn-pills btn-primary">
                    About Us{" "}
                    <i className="mdi mdi-arrow-right align-middle"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h4 className="title mb-3">What we do</h4>
                <p className="text-muted para-desc mb-0">
                  We deliver customized solutions that align with your
                  distinctive needs, and vision. From home and flood insurance,
                  life and health insurance to auto and business insurance, we
                  offer a comprehensive range of coverage options designed to
                  protect you, your family, and your assets against life's
                  uncertainties. Our team is dedicated to serving as your
                  trusted advisors, guiding you through the insurance process
                  with honesty, integrity, and expertise. Whether you're looking
                  to safeguard your home, vehicle, business, or loved ones, we
                  are committed to your peace of mind and the financial security
                  you deserve.
                </p>
                <div className="mt-4">
                  <Link
                    to={RoutesEnums.SERVICES}
                    className="btn btn-pills btn-primary"
                  >
                    Services{" "}
                    <i className="mdi mdi-arrow-right align-middle"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="about-right">
                <div className="position-relative shadow p-2 rounded-top-pill rounded-5 bg-white img-one">
                  <img
                    src={whatWeDoBig}
                    className="img-fluid rounded-top-pill rounded-5"
                    alt=""
                  />

                  <div className="position-absolute top-0 start-0 z-n1">
                    <img src={dots} className="avatar avatar-xl-large" alt="" />
                  </div>
                </div>

                <div className="img-two shadow rounded-3 overflow-hidden p-2 bg-white">
                  <img
                    src={whatWeDoSmall}
                    className="img-fluid rounded-3"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container-fluid mt-100 mt-60">
          <div
            className="position-relative overflow-hidden rounded-3 shadow py-5"
            style={{
              backgroundImage: `url(${bg5})`,
              backgroundPosition: "center",
              backgroundAttachment: "fixed",
            }}
          >
            <div className="bg-overlay"></div>
            <div className="container">
              <div className="row">
                <div className="col-4 py-3">
                  <div className="counter-box text-center">
                    <h1 className="mb-0 text-white fw-bold">
                      <CountUp start={0} end={1548} className="counter-value" />
                      +
                    </h1>
                    <h6 className="counter-head text-white fs-5 fw-semibold mb-0">
                      Investment
                    </h6>
                  </div>
                </div>

                <div className="col-4 py-3">
                  <div className="counter-box text-center">
                    <h1 className="mb-0 text-white fw-bold">
                      <CountUp start={0} end={25} className="counter-value" />+
                    </h1>
                    <h6 className="counter-head text-white fs-5 fw-semibold mb-0">
                      Awards
                    </h6>
                  </div>
                </div>

                <div className="col-4 py-3">
                  <div className="counter-box text-center">
                    <h1 className="mb-0 text-white fw-bold">
                      <CountUp start={0} end={9} className="counter-value" />+
                    </h1>
                    <h6 className="counter-head text-white fs-5 fw-semibold mb-0">
                      Profitability
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-md-6 position-relative">
              <div className="shadow rounded-3 overflow-hidden ">
                <img src={newsLetter} className="img-fluid rounded-3" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="section-title text-center">
                    <h4 className="title mb-3">
                      Fresh Perspectives: Our Insightful Newsletter
                    </h4>
                    <h6>
                      Specialized insights into the pivotal aspects of church
                      real estate that truly matter
                    </h6>
                    <div className="mt-4 pt-2">
                      <Link
                        to="/contactus"
                        className="btn btn-pills btn-primary"
                      >
                        + Subscribe
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-100 mt-60">
          <GetInTuch />
        </div>
      </section>
      <FooterTopImage />
      <Footer />
    </>
  );
}
