import React, { useState } from "react";
import { Link } from "react-router-dom";

import bg3 from "../assect/images/website/14.jpg";
import heroImg from "../assect/images/hero.jpg";
import dots from "../assect/images/svg/dots.svg";
import image1 from "../assect/images/1.jpg";
import map from "../assect/images/map.png";
import company from "../assect/images/company.jpg";
import church from "../assect/images/website/38.jpg";
import ourMission from "../assect/images/website/16.jpg";
import ourVision from "../assect/images/website/17.jpg";
import ourCompany from "../assect/images/website/18.jpg";
import avatar from "../assect/images/website/19.jpg";

import Navbar from "../components/navbar";
import About from "../components/about";
import Broker from "../components/broker";
import Blog from "../components/blog";
import GetInTuch from "../components/getInTuch";

import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/css/modal-video.css";

import CountUp from "react-countup";
import Footer from "../components/footer";

export default function AboutUs() {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                {/* <p className="text-white-50 para-desc mx-auto mb-0">
                  Our story: Piertop
                </p> */}
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  About Us
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="about-left center-items">
                <div className="position-relative shadow p-2 rounded-top-pill rounded-5 bg-white img-one">
                  <img
                    src={church}
                    className="img-fluid rounded-top-pill rounded-5"
                    alt=""
                  />
                </div>

                {/* <div className="img-two shadow rounded-3 overflow-hidden p-2 bg-white">
                  <img src={image1} className="img-fluid rounded-3" alt="" />
                </div> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h4 className="title mb-3">
                  We are about insurance excellence:
                </h4>
                <p className="text-muted para-desc mb-0">
                  At Piertop Insurance Agency, excellence is at the core of
                  everything we do. We are dedicated to delivering superior
                  service and expertise in all aspects of your insurance needs.
                  Piertop combines passion and proficiency, guided by the belief
                  that every decision should enhance your financial security and
                  peace of mind.
                  <br />
                  Our agency was founded with the mission of simplifying the
                  complexities of insurance, providing you with tailored
                  solutions that align perfectly with your unique needs and
                  objectives. We leverage our industry experience and
                  cutting-edge technologies to deliver exceptional results,
                  ensuring that every policy we offer is designed to protect
                  what matters most to you.
                  <br />
                  We take a holistic approach to insurance, recognizing that
                  your financial well-being extends beyond just one aspect of
                  coverage. That's why we offer a comprehensive range of
                  services, including home, auto, life, health, business, and
                  specialty insurance solutions, to meet all of your insurance
                  needs under one roof.
                  <br />
                  Whether you're protecting your home, securing your business,
                  or planning for your family's future, Piertop is here to
                  provide you with the expertise and support you need to achieve
                  your goals. With our commitment to excellence and personalized
                  service, you can trust Piertop to be your partner in insurance
                  every step of the way.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title">
                <h4 className="title mb-3">Our mission is your protection</h4>
                <p className="text-muted para-desc mb-0">
                  At Piertop Insurance Agency, our mission is simple: to protect
                  what matters most to you. We are here to fuel your vision of a
                  secure and prosperous future by providing exceptional
                  insurance solutions that safeguard your assets, your loved
                  ones, and your peace of mind.
                  <br />
                  We believe in empowering you with the tools and resources you
                  need to navigate life's uncertainties with confidence. Whether
                  you're planning for your family's future, growing your
                  business, or pursuing your passions, we're committed to
                  ensuring that you have the ideal coverage to meet your
                  personal and financial goals.
                  <br />
                  At Piertop Insurance, your protection is our priority. We're
                  here to support you every step of the way, empowering you to
                  pursue your vision and achieve your dreams with confidence.
                  Let us be your trusted partner in protection, guiding you
                  toward a future filled with security, prosperity, and peace of
                  mind.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="about-right center-items">
                <div className="position-relative shadow p-2 rounded-top-pill rounded-5 bg-white img-one">
                  <img
                    src={ourMission}
                    className="img-fluid rounded-top-pill rounded-5"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="about-left center-items">
                <div className="position-relative shadow p-2 rounded-top-pill rounded-5 bg-white img-one">
                  <img
                    src={ourVision}
                    className="img-fluid rounded-top-pill rounded-5"
                    alt=""
                  />
                </div>

                {/* <div className="img-two shadow rounded-3 overflow-hidden p-2 bg-white">
                  <img src={vision} className="img-fluid rounded-3" alt="" />
                </div> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h4 className="title mb-3">Our vision is your security</h4>
                <p className="text-muted para-desc mb-0">
                  At Piertop Insurance Agency, our unwavering vision is to be
                  your cornerstone in achieving financial security and peace of
                  mind. We envision a world where everyone has access to
                  best-in-class insurance services that propel them personally
                  and financially, freeing them to enjoy life and make a
                  positive impact in their communities and beyond.
                  <br />
                  We believe that by providing exceptional insurance solutions,
                  we can empower individuals, families, and businesses to pursue
                  their goals and dreams with confidence. Whether it's
                  protecting your home, securing your business, or planning for
                  your family's future, we strive to be your trusted partner in
                  security every step of the way.
                  <br />
                  Our vision extends beyond just selling insurance policies – we
                  aim to build lasting relationships with our clients based on
                  trust, transparency, and mutual respect. By delivering
                  personalized service and expert guidance, we help you navigate
                  life's uncertainties with confidence, knowing that you're
                  protected by a team that cares about your well-being.
                  <br />
                  At Piertop, your security is our vision. Let us help you
                  achieve your goals and enjoy peace of mind knowing that you're
                  protected against life's uncertainties. Join us on the journey
                  toward a future filled with security, prosperity, and success.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title">
                <h4 className="title mb-3">The company</h4>
                <p className="text-muted para-desc mb-0">
                  Piertop is founded on the yearning and zeal to serve you
                  through real estate and support you in the spreading of The
                  Good News. Driven by the need to do the most good we are
                  committed to serving religious leaders whose work have a
                  positive impact on their congregation, their community and
                  society at large. More pointedly we serve those who are tasked
                  with the mission of equipping people to live well, bless
                  others, and make a difference in the world. In helping those
                  who do the highest good Piertop has acquire its identity, and
                  purpose.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="about-right center-items">
                <div className="position-relative shadow p-2 rounded-top-pill rounded-5 bg-white img-one">
                  <img
                    src={ourCompany}
                    className="img-fluid rounded-top-pill rounded-5"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="container mt-100 mt-60">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div className="section-title ms-lg-5">
                <h4 className="title mb-3">Leadership</h4>
                <p className="text-muted para-desc mb-0">
                  At Piertop Insurance Agency, our leadership is guided by a
                  commitment to excellence, integrity, and service. Jacques
                  Pierrilus, the founder of Piertop Insurance, is a visionary
                  leader whose most important life work is his investment in his
                  family. With a professional tenure spanning over two decades,
                  Jacques has been a Stewart in the financial service industry
                  since 2004, specializing in brokerage, financing, and
                  investment.
                  <br />
                  Jacques' philosophy is simple yet profound: to provide
                  unparalleled service coupled with unwavering integrity in all
                  professional endeavors. Under his guidance, Piertop has become
                  synonymous with reliability, trustworthiness, and
                  client-centricity, setting the standard for excellence in the
                  insurance industry.
                  <br />
                  Driven by a passion for helping others and a deep-seated
                  commitment to making a positive impact, Jacques leads by
                  example, inspiring the Piertop team to embody the values of
                  honesty, diligence, and dedication in every interaction with
                  clients and colleagues alike.
                  <br />
                  With Jacques at the helm, Piertop Insurance Agency continues
                  to uphold its reputation as a leader in the insurance
                  industry, dedicated to protecting what matters most to our
                  clients and empowering them to pursue their goals and dreams
                  with confidence.
                </p>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="about-right center-items">
                <div className="position-relative shadow p-2 rounded-top-pill rounded-5 bg-white img-one">
                  <img
                    src={avatar}
                    className="img-fluid rounded-top-pill rounded-5"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container-fluid bg-building-pic mt-100 mt-60">
          <div
            className=" opacity-25 position-absolute w-100 h-100 top-0 start-0"
            style={{
              backgroundImage: `url(${map})`,
              backgroundPosition: "center",
            }}
          ></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col">
                <div className="section-title text-center mb-4 pb-2">
                  <h4 className="title mb-3">Trusted by more than 10K users</h4>
                  <p className="text-muted para-desc mb-0 mx-auto">
                    A great plateform to buy, sell and rent your properties
                    without any agent or commisions.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-4 py-3">
                <div className="counter-box text-center">
                  <h1 className="mb-0 fw-semibold">
                    <CountUp start={0} end={1548} className="counter-value" />+
                  </h1>
                  <h6 className="counter-head text-muted fw-normal">
                    Investment
                  </h6>
                </div>
              </div>

              <div className="col-4 py-3">
                <div className="counter-box text-center">
                  <h1 className="mb-0 fw-semibold">
                    <CountUp start={0} end={25} className="counter-value" />+
                  </h1>
                  <h6 className="counter-head text-muted fw-normal">Awards</h6>
                </div>
              </div>

              <div className="col-4 py-3">
                <div className="counter-box text-center">
                  <h1 className="mb-0 fw-semibold">
                    <CountUp start={0} end={9} className="counter-value" />+
                  </h1>
                  <h6 className="counter-head text-muted fw-normal">
                    Profitability
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container mt-100 mt-60">
          <GetInTuch />
        </div>
      </section>
      <Footer />
    </>
  );
}
