import realEstateAgent from "../assect/images/website/33.jpg";
import businessConsultant from "../assect/images/website/34.jpg";
import businessBroker from "../assect/images/website/35.jpg";
import investemntSpecialist from "../assect/images/website/36.jpg";
import creativeAndTechnical from "../assect/images/website/37.jpg";

export const careersData = [
  {
    image: realEstateAgent,
    title: "Insurance Sales Representative",
    sectionTitle: "Insurance Sales Representative",
    description: `At Piertop Insurance Agency, we're committed to providing exceptional service and value to our clients, helping them protect what matters most. As an Insurance Sales Representative, you'll play a vital role in helping clients navigate the complexities of insurance products and find the coverage that best meets their needs. Your responsibilities will include:`,
    requirements: [
      "Prospecting and building relationships: Expand our client base by identifying and reaching out to potential clients, understanding their insurance needs, and building lasting relationships",
      "Advising clients: Provide expert guidance and advice to clients on various insurance products, including home, auto, life, health, and business insurance, helping them make informed decisions about their coverage.",
      "Customizing insurance solutions: Tailor insurance solutions to meet each client's unique needs and circumstances, ensuring that they have the coverage they need to protect their assets and loved ones",
      "Closing deals: Negotiate terms and close insurance deals on behalf of clients, ensuring that they have the coverage they need at a price they can afford.",
      "Providing exceptional customer service: Deliver top-notch customer service and support to clients throughout the insurance process, from initial inquiry to claims resolution, building trust and loyalty.",
    ],
    qualifaction: [
      "Insurance license: Hold a valid insurance license in your state (if required).",
      "Sales experience: Proven experience in sales, preferably in the insurance industry or a related field.",
      "Negotiation skills: Strong negotiation skills and the ability to effectively close deals.",
      "Communication skills: Excellent communication and interpersonal skills, with the ability to build rapport with clients and colleagues.s",
      "Self-motivation: Self-motivated and proactive with a passion for helping others and solving problems.",
    ],
    contact:
      "If you're passionate about insurance and helping clients protect what matters most, we'd love to hear from you! Please submit your resume and cover letter detailing your relevant experience and why you're the perfect fit for this role to careers@piertop.com. Join us in making a difference in the lives of our clients and communities through the power of insurance.",
  },
  // {
  //   image: businessBroker,
  //   title: "Business Broker",
  //   sectionTitle: "Business Broker",
  //   description: `We're all about connecting people with the perfect business opportunities. From buying to selling, we're here to make sure everyone walks away happy. Join our team and help make dreams come true, one deal at a time.As a Business Broker with us, you'll play a pivotal role in facilitating business transactions for our clients. Your responsibilities will include:`,
  //   requirements: [
  //     "Generating leads and cultivating relationships with potential buyers and sellers",
  //     "Conducting thorough evaluations of businesses to determine market value and potential profitability",
  //     "Guiding clients through the negotiation process and ensuring smooth transactions",
  //     "Providing exceptional customer service and building long-term relationships with clients",
  //     "Staying up-to-date on market trends and industry regulations to provide informed guidance to clients",
  //   ],
  //   qualifaction: [
  //     "A valid real estate license in Florida",
  //     "Proven experience in business brokering or a related field",
  //     "Exceptional negotiation and communication skills",
  //     "Strong analytical skills and attention to detail",
  //     "Self-motivated with the ability to work independently and as part of a team",
  //   ],
  //   contact:
  //     "If you're a seasoned professional with a passion for business brokering and a track record of success, we'd love to hear from you! Please submit your resume and cover letter detailing your relevant experience and why you're the ideal candidate for this role to careers@piertop.com",
  // },
  // {
  //   image: investemntSpecialist,
  //   title: "Investment Specialist",
  //   sectionTitle: "Investment Specialist",
  //   description: `With a focus on delivering exceptional results and personalized service, we empower our clients to make informed decisions and achieve their investment objectives.
  //   As a Real Estate Investment Advisor with us, you will be at the forefront of assisting clients in navigating the dynamic world of real estate investment. Your primary responsibilities will include:`,
  //   requirements: [
  //     "Conducting in-depth analyses of real estate markets and properties to identify investment opportunities",
  //     "Developing customized investment strategies tailored to clients' financial goals and risk tolerance",
  //     "Advising clients on property acquisitions, dispositions, and portfolio management",
  //     "Building and maintaining strong relationships with clients, providing ongoing support and guidance",
  //     "Staying abreast of market trends, regulatory changes, and economic developments to inform investment decisions",
  //   ],
  //   qualifaction: [
  //     "Proven experience in real estate investment or financial advisory",
  //     "Strong knowledge of real estate markets, investment analysis techniques, and financial principles",
  //     "Excellent analytical skills and attention to detail",
  //     "Exceptional communication and interpersonal skills",
  //     "Ability to work independently and collaboratively in a fast-paced environment",
  //   ],
  //   contact:
  //     "If you're passionate about commercial real estate we'd love to hear from you! Please submit your resume and cover letter detailing your relevant experience and why you're the perfect fit for this role to careers@piertop.com",
  // },
  // {
  //   image: businessConsultant,
  //   title: "Business Consultant",
  //   sectionTitle: "Business Consultant",
  //   description: `We pride ourselves on providing strategic insights and actionable solutions that drive success.
  //   As a Business Consultant with us, you will have the opportunity to work closely with a diverse portfolio of clients, offering expert guidance and support to help them overcome challenges and capitalize on opportunities. Your key responsibilities will include:`,
  //   requirements: [
  //     "Conducting thorough assessments of clients' businesses, processes, and operations",
  //     "Identifying areas for improvement and opportunities for growth through data analysis and market research",
  //     "Developing tailored strategies and action plans to address clients' specific needs and objectives",
  //     "Collaborating with clients' teams to implement solutions and drive change effectively",
  //     "Providing ongoing support and guidance to ensure the successful execution and sustainability of initiatives",
  //   ],
  //   qualifaction: [
  //     "Proven experience in business consulting or a related role",
  //     "Strong analytical and problem-solving skills",
  //     "Excellent communication and interpersonal skills",
  //     "Ability to work independently and collaboratively in a fast-paced environment",
  //     "Proficiency in Microsoft Office suite and other relevant software tools",
  //   ],
  //   contact:
  //     "If you're passionate about commercial real estate we'd love to hear from you! Please submit your resume and cover letter detailing your relevant experience and why you're the perfect fit for this role to careers@piertop.com",
  // },
  // {
  //   image: creativeAndTechnical,
  //   title: "Creative & Technical",
  //   sectionTitle: "Creative & Technical",
  //   description: `We believe in the power of creativity to drive innovation and success. We're always on the lookout for talented individuals who can bring fresh ideas and perspectives to our team. Whether you're passionate about content creation, marketing, development, or other non-sales roles, we have opportunities for you to thrive and grow with us.
  //   As a member of our creative team, you'll play a vital role in shaping the future of our company. We're seeking individuals with a knack for thinking outside the box and a passion for making a difference. Your responsibilities may vary depending on the specific role, but could include:`,
  //   requirements: [
  //     "Content Creation: Developing engaging and compelling content for our website, social media channels, email campaigns, and more.",
  //     "Marketing: Planning and executing marketing strategies to promote our products or services, including branding, advertising, and market research.",
  //     "Development: Building and maintaining our digital platforms, applications, and tools to enhance the user experience and drive business growth.",
  //     "Design: Creating visually appealing graphics, layouts, and designs for various marketing materials and digital assets.",
  //     "Analytics: Analyzing data and metrics to track the performance of our marketing campaigns, websites, and digital initiatives.",
  //   ],
  //   qualifaction: [
  //     "Strong creative skills and a passion for innovation",
  //     "Excellent communication and collaboration skills",
  //     "Proficiency in relevant tools and technologies (e.g., Adobe Creative Suite, Google Analytics, HTML/CSS, etc.)",
  //     "Relevant experience or education in your chosen field",
  //     "Ability to work independently and as part of a team",
  //     "A positive attitude and a willingness to learn and grow",
  //   ],
  //   contact:
  //     "We are growing and always looking for talented Real estate Agent to join our team.",
  // },
];
