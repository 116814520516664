import React, { useState, useEffect } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import bg3 from "../../assect/images/website/pic11.jpg";
import { storage } from "../../config";
import { Collapse } from "antd";
import SellerGuide from "./sellers-guide";
import BuyersGuide from "./buyers-guide";
import LoanCalculator from "../loan-calculator";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";

const InsuranceQuote = () => {
  const [iframeWidth, setIframeWidth] = useState(560);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [propertyAddress, setPropertyAddress] = useState("");
  const [radioOption, setRadioOption] = useState("Yes");

  // const [file, setFile] = useState(null);

  // const handleFileChange = (e) => {
  //   setFile(e.target.files[0]);
  // };

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.matchMedia("(max-width: 480px)").matches
        ? 315
        : 560;
      setIframeWidth(newWidth);
    };

    // Initial call to set the initial width
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const panelStyle = {
    margin: 24,
    background: "#FFFF",
    border: "#E5E5E5 1px solid",
    borderRadius: 4,
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
  };

  const items = [
    {
      key: "1",
      label: "Sellers Guide",
      children: <SellerGuide />,
      style: panelStyle,
    },
    {
      key: "2",
      label: "Buyers Guide",
      children: (
        <p>
          <BuyersGuide />
        </p>
      ),
      style: panelStyle,
    },
  ];

  const downloadFile = (number) => {
    // if (!file) {
    //   console.error("No file selected");
    //   return;
    // }

    // const storageRef = ref(storage, `/sheets/${file.name}`);
    // uploadBytesResumable(storageRef, file)
    //   .then((snapshot) => {
    //     console.log("File uploaded successfully");
    //     getDownloadURL(snapshot.ref)
    //       .then((downloadURL) => {
    //         console.log("Download URL:", downloadURL);
    //         // Use the downloadURL as needed (e.g., save it to a database)
    //       })
    //       .catch((error) => {
    //         console.error("Error getting download URL:", error);
    //       });
    //   })
    //   .catch((error) => {
    //     console.error("Error uploading file:", error);
    //   });

    let url = "";

    // Replace 'url' with the actual URL of the file you want to download
    if (number === 1) {
      url =
        "https://firebasestorage.googleapis.com/v0/b/piertop-jaques.appspot.com/o/sheets%2Ffile_example_XLS_100.xls?alt=media&token=ea33cd39-fd5a-4c07-85da-f59df860dcd6";
    } else if (number === 2) {
      url =
        "https://firebasestorage.googleapis.com/v0/b/piertop-jaques.appspot.com/o/sheets%2Ffile_example_XLS_1000.xls?alt=media&token=0e44999d-1f2c-4f3b-8137-035ad626f1ad";
    }

    // Create an anchor element
    const link = document.createElement("a");
    link.href = url;

    // Specify the filename that will be used when the file is downloaded
    link.setAttribute("download", "dummySheet1.pdf"); // Change 'filename.pdf' to the desired filename

    // Trigger a click event on the anchor element to start the download
    document.body.appendChild(link);
    link.click();

    // Cleanup: Remove the anchor element after the download starts
    document.body.removeChild(link);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const serviceID = process.env.REACT_APP_SERVICE_ID;
    const templateID = process.env.REACT_APP_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;

    const appendedMessage =
      message +
      "\n" +
      "Property Address: " +
      propertyAddress +
      "\n" +
      "First Name: " +
      firstName +
      "\n" +
      "Last Name: " +
      lastName +
      "\n" +
      "Email: " +
      email +
      "\n" +
      "Phone: " +
      phone +
      "\n" +
      "Are you currently insured with Piertop Insurance Agency and Member Companies?: " +
      radioOption;

    const templateParams = {
      from_name: firstName,
      from_email: email,
      message: appendedMessage,
    };

    debugger;
    emailjs
      .send(serviceID, templateID, templateParams, publicKey)
      .then((response) => {
        toast.success(
          "Your message has been received! We will get back to you shortly"
        );
        document.getElementById("contact-form").reset();
        setFirstName(null);
        setLastName(null);
        setEmail(null);
        setPhone(null);
        setMessage(null);
        setPropertyAddress(null);
        setRadioOption(null);
      })
      .catch((err) => toast.error(err.text));
    // console.log('Submitting form...')
    // console.log('First Name:', firstName)
    // console.log('Last Name:', lastName)
    // console.log('Email:', email)
    // console.log('Phone:', phone)
    // console.log('Message:', message)
    // console.log('Property Address:', propertyAddress)
    // console.log('Radio Option:', radioOption)
  };

  const inputFieldStyles = {
    marginBottom: "10px",
    borderWidth: 1,
    padding: "10px",
    borderRadius: "10px",
    borderColor: "lightgray",
    width: "100%",
  };

  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg3})` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Tools
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="section">
        {/* <div className="col">
          <div className="section-title text-center mb-2 pb-2">
            <h4 className="title mb-2">Guides</h4>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <Collapse size="large" items={items} />
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center mb-2 pb-2">
                <h4 className="title mb-2">Research</h4>
                <p>
                  PierTop is committed to delivering excellence in all facets of
                  your real estate needs. PierTop bridges passion and expertise
                  while holding true to the belief that every decision should
                  promote ministry. Created with the goal of alleviating
                  religious leaders of the need to deviate from ministry, we
                  exploit synergy between industry proficiencies and theoretical
                  competencies to deliver superior results by providing
                  customized real estate solutions that align with your
                  distinctive vision and mission. We take a holistic approach to
                  religious properties to ensure that each transaction promotes
                  your long-term vision. As result we offer a range of services,
                  including selling, buying, leasing, consulting, investing,
                  property valuation, property management, and sustainability
                  services, to support your calling.
                </p>
              </div>
            </div>
          </div>
          <div className="row g-4"></div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center mb-2 pb-2">
                <h4 className="title mb-2">Videos</h4>
                <div>
                  <iframe
                    width={iframeWidth}
                    height="315"
                    src="https://media.istockphoto.com/id/1433471040/video/church-steeple-at-sunrise.mp4?s=mp4-640x640-is&k=20&c=-qKZBAlFxvxQPVhwgdSsrgNDUERUEP2BI7BV09EQ3PY="
                    title="Dummy video"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <iframe
                    width={iframeWidth}
                    height="315"
                    src="https://cdn.pixabay.com/vimeo/294031798/church-18599.mp4?width=640&hash=817fa77751e249756d2607fc55b3f25b9484bbb5"
                    title="Dummy video"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-4"></div>
        </div> */}

        <div className="container">
          <div className="row justify-content-center">
            <div className="col">
              {/* <div className="section-title text-center mb-2 pb-2">
            
                <Link to="/tools" >
                <h4 className="title mb-2" style={{cursor:'pointer'}}>Back</h4>
                </Link>
              
              </div> */}
              <div
                style={{
                  padding: 4,
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <div>
                  <h2 className="text-center">Home Insurance Quote</h2>
                </div>
                <form id="contact-form" onSubmit={handleSubmit}>
                  <div>
                    <h6>First Name*</h6>
                    <input
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                      style={inputFieldStyles}
                      placeholder="Your Name"
                    />
                  </div>
                  <div>
                    <h6>Last Name*</h6>
                    <input
                      required
                      onChange={(e) => setLastName(e.target.value)}
                      style={inputFieldStyles}
                      placeholder="Last Name"
                    />
                  </div>
                  <div>
                    <h6>Your Email*</h6>
                    <input
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputFieldStyles}
                      placeholder="Your Email"
                      type="email"
                    />
                  </div>
                  <div>
                    <h6>Your Phone Number*</h6>
                    <input
                      required
                      onChange={(e) => setPhone(e.target.value)}
                      style={inputFieldStyles}
                      placeholder="Your Phone Number"
                    />
                  </div>
                  <div>
                    <h6>Property Address*</h6>
                    <input
                      required
                      onChange={(e) => setPropertyAddress(e.target.value)}
                      style={inputFieldStyles}
                      placeholder="Property Address"
                    />
                  </div>
                  <div>
                    <h6>Your Message*</h6>
                    <textarea
                      required
                      onChange={(e) => setMessage(e.target.value)}
                      style={inputFieldStyles}
                      placeholder="Your Message"
                    />
                  </div>
                  <div>
                    <h6>
                      Are you currently insured with Piertop Insurance Agency
                      and Member Companies? *
                    </h6>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <label style={{ margin: "0 10px" }}>
                      <input
                        onChange={(e) => setRadioOption(e.target.value)}
                        type="radio"
                        name="option"
                        value="Yes"
                      />{" "}
                      Yes
                    </label>
                    <label style={{ margin: "0 10px" }}>
                      <input
                        onChange={(e) => setRadioOption(e.target.value)}
                        type="radio"
                        name="option"
                        value="No"
                      />{" "}
                      No
                    </label>
                  </div>
                  <div>
                    <button
                      type="submit"
                      style={{
                        marginTop: "10px",
                        width: "100%",
                        backgroundColor: "#14213d",
                        color: "white",
                        padding: "10px 0",
                        fontSize: "16px",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <h5 style={{ margin: "0" }}>Submit</h5>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="row g-4"></div>
        </div>

        {/* <div className="container mt-100">
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center mb-2 pb-2">
                <h4 className="title mb-2">Spreadsheets</h4>
               
                <div
                  className="title"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  Dummy sheet one
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    downloadFile(1);
                  }}
                >
                  Donwload
                </button>
                <div
                  className="title"
                  style={{
                    marginTop: "10px",
                  }}
                >
                  Dummy sheet two
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    downloadFile(2);
                  }}
                >
                  Download
                </button>
              </div>
            </div>
          </div>
          <div className="row g-4"></div>
        </div> */}
      </section>
      <Footer />
    </>
  );
};
export default InsuranceQuote;
