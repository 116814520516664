import React from "react";
import { brokerData } from "../data/data";
import { Link, useNavigate } from "react-router-dom";
import { careersData } from "../data/careersData";
import RoutesEnums from "../enums/routes.enums";

export default function Broker() {
  let navigate = useNavigate();

  return (
    <>
      <div className="row justify-content-center">
        <div className="col">
          <div className="section-title text-center mb-4 pb-2">
            <h4 className="title mb-3">Join Our Team</h4>
            <p className="text-muted para-desc mb-0 mx-auto">
              Join us and be a part of our dedicated team of innovative minds
              driving excellence and collaboration to shape the future.
            </p>
          </div>
        </div>
      </div>

      <div className="row g-4 mt-0">
        {brokerData.map((item, index) => {
          return (
            <div className="col-lg-3 col-md-4 col-12" key={index}>
              <div className="card team team-primary text-center">
                <div
                  className="card-img team-image d-inline-block mx-auto rounded-pill avatar avatar-ex-large overflow-hidden"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(RoutesEnums.CAREER_DETAIL, {
                      state: careersData[index],
                    });
                  }}
                >
                  <img
                    src={item.image}
                    className="img-fluid"
                    alt=""
                    style={{
                      height: "100%",
                    }}
                  />
                  <div className="card-overlay avatar avatar-ex-large rounded-pill"></div>
                </div>
                <div className="content mt-3">
                  <div
                    onClick={() => {
                      navigate(RoutesEnums.CAREER_DETAIL, {
                        state: careersData[index],
                      });
                    }}
                    className="text-dark h5 mb-0 title"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {item.title}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
