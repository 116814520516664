import { useState, React, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { auth } from "../../config";
import bg3 from "../../assect/images/website/7.jpg";
import logo from "../../assect/images/logo-icon-80.png";
import { toastUtil } from "../../utils/toast.utils";
import Spinner from "../../common/loading-spinner";
import RoutesEnums from "../../enums/routes.enums";

export default function Signup() {
  const navigate = useNavigate();
  const recaptcha = useRef();
  const [user, setUser] = useState({
    firstName: "",
    email: "",
    password: "",
    acceptedTerms: false,
  });
  const [loading, setLoading] = useState(false);

  const getUserData = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };
  const handleCheckboxChange = (event) => {
    setUser({
      ...user,
      acceptedTerms: event.target.checked, // Update the state with checkbox value
    });
  };
  const isEmailValid = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const postUserData = (e) => {
    e.preventDefault();
    if (!user.acceptedTerms) {
      toast.error("Please accept the terms and conditions", toastUtil);
      return;
    }

    if (!isEmailValid(user.email)) {
      toast.error("Please provide a valid email address", toastUtil);
      return;
    }

    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      toast.error("Please verify the Recaptcha!", toastUtil);
      return;
    }

    setLoading(true);

    createUserWithEmailAndPassword(auth, user.email, user.password)
      .then((userCredential) => {
        const userAuth = userCredential.user;
        updateProfile(userAuth, {
          displayName: user.firstName,
        }).then(() => {
          sendVerificationEmail(userAuth);
          setLoading(false);
          toast.success("Account created. Verification email sent.", toastUtil);
          navigate(RoutesEnums.LOGIN);
        });
      })
      .catch((error) => {
        if (error.message.startsWith("Firebase")) {
          error.message = error.message.replace("Firebase: ", "");
        }
        toast.error(error.message, toastUtil);
        setLoading(false);
      });
  };

  const sendVerificationEmail = (user) => {
    sendEmailVerification(user)
      .then(() => {
        //console.log("Verification email sent");
      })
      .catch((error) => {
        console.error("Error sending verification email:", error.message);
      });
  };
  return (
    <>
      {loading && <Spinner />}
      <section
        className="bg-home zoom-image d-flex align-items-center"
        style={{
          backgroundImage: `url(${bg3})`,
        }}
      >
        <div className="bg-overlay bg-gradient-overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="p-4 bg-white rounded-3 shadow-md mx-auto w-100"
                style={{ maxWidth: "400px" }}
              >
                <form onSubmit={postUserData}>
                  <Link to="/">
                    <img src={logo} className="mb-4 d-block mx-auto" alt="" />
                  </Link>
                  <h5 className="mb-3">Register your account</h5>

                  <div className="form-floating mb-2">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder="Harry"
                      name="firstName"
                      value={user.firstName}
                      onChange={getUserData}
                      required
                    />
                    <label htmlFor="floatingInput">First Name</label>
                  </div>

                  <div className="form-floating mb-2">
                    <input
                      type="email"
                      className="form-control"
                      id="floatingEmail"
                      placeholder="name@example.com"
                      name="email"
                      value={user.email}
                      onChange={getUserData}
                      required
                    />
                    <label htmlFor="floatingEmail">Email Address</label>
                  </div>

                  <div className="form-floating mb-3">
                    <input
                      type="password"
                      className="form-control"
                      id="floatingPassword"
                      placeholder="Password"
                      name="password"
                      value={user.password}
                      onChange={getUserData}
                      required
                    />
                    <label htmlFor="floatingPassword">Password</label>
                  </div>

                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                      onChange={handleCheckboxChange}
                      required
                    />
                    <label
                      style={{ border: "#000 !important" }}
                      className="form-check-label text-muted"
                      htmlFor="flexCheckDefault"
                    >
                      I Accept{" "}
                      <Link to="#" className="text-primary">
                        Terms And Condition
                      </Link>
                    </label>
                  </div>

                  <ReCAPTCHA
                    ref={recaptcha}
                    sitekey={process.env.REACT_APP_SITE_KEY}
                  />

                  <button className="btn btn-primary w-100 mt-3" type="submit">
                    Register
                  </button>

                  <div className="col-12 text-center mt-3">
                    <span>
                      <span className="text-muted me-2">
                        Already have an account ?{" "}
                      </span>{" "}
                      <Link to="/auth-login" className="text-dark fw-medium">
                        Sign in
                      </Link>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
