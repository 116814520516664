import sellerRepresentation from "../assect/images/website/20.jpg";
import landlordRepresentation from "../assect/images/website/21.jpg";
import buyerRepresentation from "../assect/images/website/22.jpg";
import tenantRepresentation from "../assect/images/website/23.jpg";
import investorRepresentation from "../assect/images/website/24.jpg";
import propertyManagement from "../assect/images/website/25.jpg";
import businessServices from "../assect/images/website/26.jpg";
import home from "../assect/images/website/pic27.jpg";
import flood from "../assect/images/website/pic28.jpg";
import health from "../assect/images/website/pic29.jpg";
import life from "../assect/images/website/lifeinsurance.jpg";
import auto from "../assect/images/website/pic31.jpg";
import business from "../assect/images/website/pic32.jpg";
import annuities from "../assect/images/website/pic33.jpg";

export const data = [
  {
    image: buyerRepresentation,
    title: "Buy a church property",
    sectionTitle: "Buy a church",
    overview: `Your vision reaches beyond bricks and mortar; it's about finding the perfect space for your congregation to flourish and minister. We recognize the profound significance of this transaction and approach your needs with diligence, and respect. Religious organizations have needs that differ from conventional real estate and we pride ourselves in understanding your demands and working with you to acquire the best property to house God’s people.  `,
    description: `We don't just facilitate transactions. We partner and guide you through the journey of acquiring your ideal space. we craft tailored solutions to match your vision. From exhaustive property searches to skillful negotiations, we stand with you in securing the sacred space you and your community deserves from start to finish.`,
    keyFeatures: [
      "Property Search: A comprehensive property search tailored to your specific requirements.",
      "Strategic Negotiations: Expert negotiation to obtain favorable terms and price.",
      "Market Expertise: In-depth market analysis to enable informed decisions.",
    ],
  },
  {
    image: sellerRepresentation,
    title: "Selling church property",
    sectionTitle: "Selling a church",
    overview: `Your church property is more than just a building - it's a footprint of your congregation's legacy and 
      it’s impact in your community. Parting with it requires prayerful consideration. The decision will play an important 
      role in your ministry going forward. We understand the emotional and psychological ramifications of selling church 
      property, and we approach every sale with care. Selling properties of religious organizations can be a complex and 
      time-consuming process; we have the experience and knowledge to navigate you through the intricacies of the market. 
      With a deep understanding of the unique challenges of church properties, we help our clients maximize their return on 
      investment while ensuring a smooth and hassle-free sale.`,
    description: `Your property isn't just listed; it's presented to inspire, attracting the perfect steward for the legacy you've built. We showcase the existing use and the potential of your space to get you the most for your property. We excel in strategic property life, targeted marketing, and negotiations. `,
    keyFeatures: [
      "Strategic Valuation: Comprehensive property valuation for optimal pricing.",
      "Inspired Marketing: Creative and targeted marketing to attract the right buyer.",
      "Negotiation Excellence: Skillful negotiation to achieve the best price and terms.",
    ],
  },
  {
    image: tenantRepresentation,
    title: "Rent a church property",
    sectionTitle: "Tenant Representation",
    overview: `Where you house your congregation is not a simple matter of sticks and stones. It’s about ministry, nurture, community, and growth. We realize the value of meeting the current and future needs of your members while remaining within budget. Moreover, since every church is unique, we work with you to discover your organizations needs and find the ideal property for you.  With a keen insight of the market, zoning, nonprofit financials, and more, we tailor our search to find a place where your ministry can thrive. From negotiations to legal considerations, we ensure your lease is in harmony with your vision. `,
    description: `On your quest to find the perfect property, let us navigate you through the journey of acquiring your ideal space. We go beyond just facilitating a transaction by guiding you through every step of the process. From discovering your needs, through negotiations, to moving in; we work tirelessly to ensure the process is hassle free and efficient. We are your partner in securing the sacred space your congregation needs to thrive.`,
    keyFeatures: [
      `Tailored Property Search: Finding a space that aligns perfectly with your congregation's needs.`,
      `Lease Negotiation: Expertise in negotiating leases that suit your community.`,
      `Market Insights: In-depth knowledge of local zoning and market conditions.`,
    ],
  },
  {
    image: landlordRepresentation,
    title: "Landlord Representation",
    sectionTitle: "Lease out a church property",
    overview: `Your sacred property is not just a building; it's a cornerstone for meaningful community engagement. Your ministries image is affected by how your facilities are used and the working relationships you maintain in the community. As a result, finding the right tenant to occupy your sacred space is paramount. We are devoted to attracting tenants that will be good stewards of your property and getting you the highest financial return.`,
    description: `Targeted marketing, showcasing the property, efficient lease management, and strategies to maximize the value of your space: we focus on making the leasing process stress free. We handle all your needs to free you to focus on ministry. `,
    keyFeatures: [
      "Targeted Marketing: Strategic marketing to attract suitable and committed tenants.",
      "Lease Management: Efficient management and documentation of lease agreements.",
      "Value Optimization: Strategies to maximize the value of your property.",
    ],
  },
  {
    image: investorRepresentation,
    title: "Investor Representation",
    sectionTitle: "Invest In Church Property ",
    overview: `At Piertop, we understand that investors have unique goals and a satisfactory return on investment differ for each investor. As you scan the commercial real estate landscape for opportunities to grow your portfolio and make a lasting impact in the community; consider religious properties. This niche affords the ability to meet your financial return expectations and be a blessing. Whether you're interested in diversifying your current portfolio, development, or income-generating assets; were here to help you achieve your goals. `,
    description: `Investing in properties to be used by religious organization comes with it’s complexities and intricacies. We go beyond transactions; we're about creating strategies that maximize your returns and minimize your risks. From thorough property evaluations to skillful negotiations, we're your partners every step of the way.`,
    keyFeatures: [
      "Strategic Property Evaluation: Comprehensive analysis tailored to your investment criteria.",
      "Expert Negotiation: Skillful bargaining to secure favorable terms and maximize profitability.",
      "Market Insights: In-depth research and analysis to support well-informed investment decisions.",
    ],
  },
  {
    image: propertyManagement,
    title: "Church Property Management",
    sectionTitle: "Church Property Management",
    overview: `Whether you’re looking to free up your time of the day-to-day grind of managing a single unit or a portfolio, we are here to help. Our perspective is real estate portfolio management is about orchestrating strategies that optimize returns and ensure sustained growth; and property management is about crafting experiences for both owners and tenants that leave a lasting impression.
      `,
    description: `Our property management services have you covered. We create customized strategies that boost your property's value and keep your tenants happy. We handle the routine tasks, stay on top of maintenance, and provide accurate reporting to free you to focus on your goals.`,
    keyFeatures: [
      "Customized Portfolio Strategies: We'll collaborate with you to develop personalized plans that align with your objectives.",
      "Proactive Asset Management: Rely on us to implement proactive measures that safeguard the performance and resilience of your portfolio or property.",
      "Investor Satisfaction: We prioritize your satisfaction and strive to deliver results that exceed your expectations, ensuring a fruitful and rewarding partnership.",
    ],
  },
  {
    image: businessServices,
    title: "Church Owned Business",
    sectionTitle: "Church Owned Business",
    overview: `As a religious organization, at times opportunities to impact your community through commerce is the proper fit for your ministry. We get it; selling, buying, starting, and operating a business is a big deal. We want to support you as a leader in the journey, whether you're passing on your legacy or pursuing a new vision, we want to ensure your success.
      `,
    description: `As the nuances of your calling become apparent, we want to be there for you. The time might be right to invest in purchasing or building a company that mirrors your mission, we are here to engage with you in the undertaking with expert guidance. Perhaps the time has come to part with a business, we are here to navigate you through that process.`,
    keyFeatures: [
      "Buy a Business: We'll search far and wide to help you acquire the business that is the perfect fit for your vision, goals, resources, and financial return requirements. Every aspect from negotiating to a smooth transition into ownership is done with utmost care to ensure your success.",
      "Sell a Business: Craft compelling stories and present financials that showcase the value and importance of your business to attract the perfect buyer while protecting your privacy throughout the selling process.",
      "Management Consulting: We believe that you should dream big, plan wisely and execute judiciously. The entrepreneurial journey need not be lonely; we are here for you at every crucial step: startup, finance, marketing, operations, planning, growing, recruiting, and stabilizing and even exit.",
    ],
  },
];

export const data2 = [
  {
    image: home,
    title: "Homeowners Insurance",
    sectionTitle: "Homeowners Insurance",
    overview: `Your home is more than just a structure; it's where memories are made, and your most valuable possessions are kept safe. At Piertop Insurance Agency, we understand the importance of protecting your home and everything it represents. Our homeownes insurance provides comprehensive coverage tailored to your specific needs, ensuring that you have the peace of mind to enjoy your home to the fullest.      
      `,
    description: `With our homeowners insurance, you can have confidence knowing that your home and everything in it is protected, giving you the freedom to focus on making memories with your loved ones. Our homeowners insurance is businessed to protect your home, personal belongings, and liability in the event of unforeseen circumstances such as fire, theft, vandalism, or natural disasters. Our policies offer coverage for the structure of your home, as well as additional structures on your property, such as garages or sheds. We also provide coverage for your personal belongings, including furniture, electronics, and priced possessions, both inside and outside of your home. Additionally, our liability coverage protects you in the event that someone is injured on your property or you are found legally responsible for damages to someone else's property.`,
    keyFeatures: [
      "Comprehensive Coverage: Protection for your home, personal belongings, and liability.",
      "Flexible Options: Customizable coverage options to meet your specific needs and budget.",
      "Additional Living Expenses: Coverage for additional living expenses if your home becomes uninhabitable due to a covered loss.",
      "Personalized Service: Dedicated insurance advisors to guide you through the insurance process and help you find the right coverage for your home.",
    ],
  },
  {
    image: flood,
    title: "Flood Insurance",
    sectionTitle: "Flood Insurance",
    overview: `While your home is your sanctuary, it's vulnerable to various risks, including flooding. Flood damage can be devastating, both emotionally and financially, and standard homeowners insurance often doesn't cover it. That's where our Flood Insurance comes in. We're here to provide you with the protection you need to safeguard your home and belongings against the unpredictable forces of nature. With our Flood Insurance, you can rest easy knowing that you're protected against one of the most common and devastating natural disasters. Don't wait until it's too late – safeguard your home and belongings with flood insurance today. 
      `,
    description: `Our Flood Insurance is specifically designed to provide coverage for damage caused by flooding, whether it's from heavy rainfall, storm surges, or overflowing rivers and streams. Our policies offer coverage for both the structure of your home and its contents, including furniture, appliances, and personal belongings. We understand that every home is unique, which is why we offer flexible coverage options to meet your specific needs and budget.
    
    In addition to protecting your home and belongings, our Flood Insurance also provides coverage for cleanup and restoration expenses, including debris removal, mold remediation, and structural repairs. We work closely with you to ensure that they have the coverage they need to recover quickly and efficiently in the event of a flood-related loss.
    
    
    `,
    keyFeatures: [
      "Comprehensive Coverage: Protection for your home and personal belongings against flood damage.",
      "Flexible Options: Customizable coverage limits and deductibles to fit your individual needs.",
      "Peace of Mind: Assurance that you're prepared for the unexpected and can recover quickly in the event of a flood.",
      "Expert Guidance: Knowledgeable insurance advisors to help you understand your coverage options and make informed decisions.",
    ],
  },
  {
    image: health,
    title: "Health Insurance",
    sectionTitle: "Health Insurance",
    overview: `Your health is your most valuable asset, and having the right health insurance coverage is essential for ensuring that you and your loved ones can access the care you need when you need it. At Piertop Insurance Agency, we understand the importance of protecting your health and well-being. That's why we offer comprehensive health insurance solutions designed to provide you with peace of mind and financial security in the face of medical expenses.
    With our Health Insurance, you can have confidence knowing that you and your loved ones are protected against the financial burden of medical expenses, allowing you to focus on what matters most – your health and well-being.
          
      `,
    description: `Our Health Insurance plans are designed to provide coverage for a wide range of medical expenses, including doctor's visits, hospital stays, prescription medications, and preventive care services. Our policies offer flexibility and choice, allowing you to select the coverage options that best meet your needs and budget.

    In addition to traditional health insurance plans, we also offer supplementary coverage options, such as dental insurance, vision insurance, and supplemental health plans, to provide you with comprehensive protection for all aspects of your health.
    
    We understand that navigating the healthcare system can be complex, which is why our team of experienced insurance professionals is here to guide you every step of the way. Whether you have questions about your coverage, need assistance with claims processing, or are exploring your options for additional coverage, we're here to provide you with the support and guidance you need to make informed decisions about your health insurance.`,
    keyFeatures: [
      "Comprehensive Coverage: Protection for a wide range of medical expenses, including doctor's visits, hospital stays, and prescription medications.",
      "Flexible Options: Customizable coverage plans to fit your individual needs and budget.",
      "Supplementary Coverage: Additional options for dental insurance, vision insurance, and supplemental health plans to enhance your coverage.",
      "Personalized Service: Dedicated insurance advisors to help you navigate the complexities of the healthcare system and make informed decisions about your coverage.",
    ],
  },
  {
    image: life,
    title: "Life Insurance",
    sectionTitle: "Life Insurance",
    overview: `Life is full of uncertainties, but securing the financial future of your loved ones shouldn't be one of them. At Piertop Insurance Agency, we understand the importance of protecting your family's financial stability in the event of the unexpected. That's why we offer comprehensive life insurance solutions designed to provide you with peace of mind and reassurance that your loved ones will be taken care of no matter what the future holds.
    With our Life Insurance, you can have confidence knowing that your loved ones will be financially protected and supported, allowing you to focus on enjoying life to the fullest.
          
      `,
    description: `Our Life Insurance plans are designed to provide financial protection for your loved ones in the event of your passing. Whether you're looking to replace lost income, pay off debts, cover funeral expenses, or leave a legacy for future generations, our life insurance policies offer flexible coverage options to meet your specific needs and goals. 
    
      We offer a variety of life insurance products, including term life insurance, and whole life insurance, each with its own unique features and benefits. Our experienced insurance professionals will work closely with you to understand your financial situation, lifestyle, and long-term objectives, helping you choose the right life insurance policy to fit your needs and budget.

      In addition to providing financial security for your loved ones, life insurance can also be used as a tool for estate planning, charitable giving, and business continuation. Whatever your goals may be, we are here to help you navigate your life insurance options and make informed decisions about your coverage.
      `,
    keyFeatures: [
      "Financial Protection: Coverage to provide for your loved ones in the event of your passing, including income replacement, debt repayment, and final expenses.",
      "Flexible Options: Variety of life insurance products with customizable coverage levels and premium payments to fit your individual needs and budget",
      "Long-Term Security: Assurance that your loved ones will be taken care of financially, giving you peace of mind and reassurance.",
      "Estate Planning Benefits: Opportunity to use life insurance as a tool for estate planning, charitable giving, and business continuation.",
    ],
  },
  {
    image: auto,
    title: "Auto Insurance",
    sectionTitle: "Auto Insurance",
    overview: `Your vehicle is more than just a mode of transportation; it's an essential part of your daily life and a significant investment. At Piertop Insurance Agency, we understand the importance of protecting your vehicle and your financial security while you’re on the road. That's why we offer comprehensive auto insurance solutions designed to provide you with peace of mind and protection against the unexpected.
    With our Auto Insurance, you can have confidence knowing that you're protected on the road, allowing you to focus on enjoying the journey ahead. Drive with peace of mind knowing that you're covered by a team that cares about your safety and security.
           
      `,
    description: `Our Auto Insurance plans are designed to provide coverage for your vehicle, as well as liability protection in the event of an accident. Our policies offer comprehensive coverage options to meet your specific needs, including coverage for damage to your vehicle, medical expenses, and legal expenses resulting from an accident.

    We offer a variety of auto insurance coverage options, including liability coverage, collision coverage, comprehensive coverage, uninsured/underinsured motorist coverage, and personal injury protection (PIP). Our experienced insurance professionals will work closely with you to understand your driving habits, vehicle usage, and coverage requirements, helping you choose the right auto insurance policy to fit your needs and budget.
    In addition to protecting your vehicle and your financial security, auto insurance can also provide you peace of mind knowing that you're covered in the event of an accident, theft, or other unexpected events on the road. With our Auto Insurance, you can drive with confidence knowing that you're protected against life's uncertainties.
    `,
    keyFeatures: [
      "Vehicle Protection: Coverage for damage to your vehicle in the event of an accident, theft, or vandalism.",
      "Liability Protection: Protection against financial liability for bodily injury and property damage caused to others in an accident",
      "Comprehensive Coverage: Coverage for non-collision-related incidents, such as theft, vandalism, fire, or natural disasters.",
      "Customizable Options: Variety of coverage options and deductibles to fit your individual needs and budget.",
    ],
  },
  {
    image: business,
    title: "Business Insurance",
    sectionTitle: "Business Insurance",
    overview: `Your business is the result of your hard work, dedication, and passion. It's a valuable asset that deserves protection against the unexpected challenges that can arise. At Piertop Insurance Agency, we understand the unique risks that businesses face, which is why we offer comprehensive business insurance solutions tailored to your specific industry and needs. With our business insurance coverage, you can focus on growing your business with confidence, knowing that you're protected against potential liabilities and losses.
    With Piertop’s Business Insurance, you can have confidence knowing that your business is protected against the unexpected, allowing you to focus on achieving your goals and building a brighter future for your enterprise.
          
      `,
    description: `Piertop Insurance plans are designed to provide comprehensive coverage for small, medium, and large businesses across various industries. Our policies offer protection against a wide range of risks, including property damage, liability claims, business interruption, employee injuries, and more.
    We offer a variety of business insurance coverage options, including general liability insurance, commercial property insurance, business interruption insurance, workers' compensation insurance, professional liability insurance, cyber liability insurance, and commercial auto insurance. Our experienced insurance professionals will work closely with you to assess your business's unique risks and needs, helping you customize a coverage package that provides the protection you need to succeed.
    In addition to protecting your business assets and financial security, business insurance can also help you attract and retain top talent, build trust with clients and customers, and comply with legal requirements and industry regulations. With Piertop Insurance, you can focus on what you do best – running and growing your business – while we handle the rest.
    `,
    keyFeatures: [
      "Comprehensive Coverage: Protection for your business assets, property, and liabilities against a wide range of risks and perils.",
      "Customizable Options: Tailored coverage options and limits to meet your specific industry, size, and risk profile.",
      "Risk Management Solutions: Proactive risk management strategies and resources to help you mitigate and prevent potential losses.",
      "Dedicated Support: Experienced insurance advisors to provide guidance and support throughout the insurance process, from policy selection to claims management.",
    ],
  },
  {
    image: annuities,
    title: "Annuities",
    sectionTitle: "Annuities",
    overview: `Planning for your financial future is essential, especially when it comes to retirement. Annuities offer a reliable and flexible way to build and protect your retirement savings, providing you with a steady stream of income to support your lifestyle in your golden years. At Piertop Insurance Agency, we understand the importance of retirement planning, which is why we offer a range of annuity options designed to help you achieve your long-term financial goals and enjoy a comfortable retirement. 
    With Piertop Annuities, you can have confidence knowing that your retirement savings are protected and working for you, allowing you to enjoy a worry-free retirement filled with financial security and peace of mind.
   
          
      `,
    description: `Annuities are financial products that provide a guaranteed income stream over a specified period, typically during retirement. Our experienced insurance professionals will work closely with you to understand your retirement goals, risk tolerance, and financial situation, helping you select the right annuity product to meet your needs and objectives. Whether you're looking to supplement your retirement income, protect your savings from market volatility, or leave a legacy for future generations, Piertop has the annuity solutions you need to achieve your financial goals.
    `,
    keyFeatures: [
      "Guaranteed Income: Reliable and predictable income stream to support your retirement lifestyle.",
      "Investment Options: Variety of annuity options to meet your financial objectives.",
      "Tax Advantages: Potential tax-deferred growth on your investment earnings and tax-efficient income distributions in retirement.",
      "Flexible Payout Options: Choice of payout options, including lifetime income, fixed period, or lump-sum withdrawals, to meet your income needs and preferences.",
    ],
  },
];
