import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/navbar";
import bg4 from "../assect/images/bg/contactus.jpg";
import { FiPhone, FiHexagon, FiMapPin, FiMail } from "../assect/icons/vander";
import emailjs from "@emailjs/browser";
import Footer from "../components/footer";
import "../App.css";
import Spinner from "../common/loading-spinner";
import { toast } from "react-toastify";
import { toastUtil } from "../utils/toast.utils";

export default function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const serviceId = process.env.REACT_APP_SERVICE_ID;
  const templateId = process.env.REACT_APP_TEMPLATE_ID;
  const publicKey = process.env.REACT_APP_PUBLIC_KEY;

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();

    const appendedMessage =
      message +
      "\n" +
      "First Name: " +
      name +
      "\n" +
      "Email: " +
      email +
      "\n" +
      "Phone: " +
      phone +
      "\n" +
      "Address: " +
      address +
      "\n";

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "PierTop",
      message: appendedMessage,
    };

    emailjs.send(serviceId, templateId, templateParams, publicKey).then(
      function (response) {
        //console.log("SUCCESS!", response.status, response.text);
        setEmail("");
        setName("");
        setPhone("");
        setAddress("");
        setMessage("");
        toast.success(
          "Your message has been received! We will get back to you shortly",
          toastUtil
        );
        setLoading(false);
      },
      function (error) {
        toast.error("Error sending mail" + error, toastUtil);
        setLoading(false);
      }
    );
  };

  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        logolight={true}
        menuClass="navigation-menu nav-left nav-light"
      />
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${bg4})` }}
      >
        {loading && <Spinner />}
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <p className="text-white-50 para-desc mx-auto mb-0">
                  Get in touch !
                </p>
                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">
                  Contact us
                </h5>
              </div>
            </div>
          </div>
          <div className="position-middle-bottom">
            <nav aria-label="breadcrumb" className="d-block">
              <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/">PierTop</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Contact us
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="section pb-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="p-4 rounded-3 shadow">
                <form>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Your Name <span className="text-danger">*</span>
                        </label>
                        <input
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          name="name"
                          id="name"
                          type="text"
                          className="form-control inputForm"
                          placeholder="Name :"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="mb-3">
                        <label className="form-label">
                          Your Email <span className="text-danger">*</span>
                        </label>
                        <input
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          name="email"
                          id="email"
                          type="email"
                          className="form-control inputForm"
                          placeholder="Email :"
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="mb-3">
                        <label className="form-label">
                          Your Phone Number
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          name="phone"
                          id="phone"
                          type="text"
                          className="form-control inputForm"
                          placeholder="Phone Number :"
                        />
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="mb-3">
                        <label className="form-label">
                          Your Address <span className="text-danger">*</span>
                        </label>
                        <input
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          name="address"
                          id="address"
                          type="address"
                          className="form-control inputForm"
                          placeholder="Address :"
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Message <span className="text-danger">*</span>
                        </label>
                        <textarea
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          name="comments"
                          id="comments"
                          rows="4"
                          className="form-control messageBoxBorder"
                          placeholder="Message :"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="d-grid">
                        <button
                          onClick={handleSubmit}
                          type="submit"
                          id="submit"
                          name="send"
                          className="btn btn-primary"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-100 mt-60 end-section">
          <div className="row g-4 mb-50">
            <div className="col-md-4">
              <div className="position-relative features text-center mx-lg-4 px-md-1">
                <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                  <FiHexagon className="hexagon" />
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <FiPhone className="fea icon-m-md text-primary" />
                  </div>
                </div>

                <div className="mt-4">
                  <h5 className="mb-3">Phone</h5>
                  <p className="text-muted">
                    Start working with Piertop that can provide everything
                  </p>
                  <Link to="tel:+561-771-0099" className="text-primary">
                    +561-771-0099
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="position-relative features text-center mx-lg-4 px-md-1">
                <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                  <FiHexagon className="hexagon" />
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <FiMail className="fea icon-m-md text-primary" />
                  </div>
                </div>

                <div className="mt-4">
                  <h5 className="mb-3">Email</h5>
                  <p className="text-muted">
                    Start working with Piertop that can provide everything
                  </p>
                  <Link
                    to="mailto:connect@piertop.com"
                    className="text-primary"
                  >
                    connect@piertop.com
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="position-relative features text-center mx-lg-4 px-md-1">
                <div className="feature-icon position-relative overflow-hidden d-flex justify-content-center">
                  <FiHexagon className="hexagon" />
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <FiMapPin className="fea icon-m-md text-primary" />
                  </div>
                </div>

                <div className="mt-4">
                  <h5 className="mb-3">Location</h5>
                  <p className="text-muted">
                    1645 Palm Beach Lakes Blvd. <br />
                    Suite 1200 <br />
                    West Palm Beach, FL, 33401 <br />
                  </p>
                  {/* <Link to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" className="lightbox text-primary" data-type="iframe" data-group="iframe" data-width="1024px" data-height="576px">View on Google map</Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container-fluid mt-100 mt-60">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="card map border-0">
                            <div className="card-body p-0">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" style={{border:"0" }} title="Townter" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
      </section>
      <Footer />
    </>
  );
}
